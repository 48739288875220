<template>
   <router-view />
</template>
<script>
//import axios from 'axios';
export default {
  name: 'App',
  components: {}
}
</script>

