<template>
    <el-container>
        <el-header></el-header>
        <el-container >
            <el-aside width="280px" class="aside">
                <el-row class="a-el-row">
                    <el-col :span="4" v-for="at in articleTypes" v-bind:key="at.id" class="a-el-col" > 
                        <h4>{{at.name }}</h4>
                    </el-col>
                </el-row>
            </el-aside>
            <el-main class="el-main">Main</el-main>
        </el-container>
        <el-footer></el-footer>
    </el-container>
</template>
<style scoped>
.a-el-row{
    margin: 20px;
}
.a-el-col{
    writing-mode: vertical-lr;
    font-size: 18px;
    color: #FFF;
}
.a-el-col h4{
    text-align: start;
    font-size: 30px;
    margin: 0;
}
.el-header,
.el-footer {
  background-image: url("../assets/image/background_header.png");
  background-size: 80px;
  /* color: #333; */
  text-align: center;
}
.el-container.is-vertical {
  flex-direction: column;
  height: 100vh;
}
.el-main{
    background-image: url("../assets/image/background_aside.png");
    background-size: 30px;
    border-radius: 10px;
    margin: 20px;
}
.aside {
  background-image: url("../assets/image/background_aside.png");
  background-size: 30px;
  text-align: center;
  line-height: 200px;
  width: 200px;
  margin: 20px 0 20px 20px;
  background-color: #fff;
  border-radius: 10px;
}
</style>
<script>
import $http from '../http/index.js';
import { ref,onMounted } from 'vue'
export default {
    name: "homeView",
    setup(){
        const articleTypes = ref([]);
        onMounted(async () => {
            $http.get("/commont/getArtucleTypeList").then(res=>{
                if(res.code ==='0'){
                    console.log(res.data);
                    articleTypes.value =res.data;
                }
            });
        });
        return{
            articleTypes
        }
    }
}
</script>