<template>
    <div class="box">
        <div class="content">
            <h2 class="title-h2">ᠳᠠᠩᠰᠠᠯᠠᠬᠣ </h2>
            <el-form :model="registerData" :rules="rules" ref="registerData" class="register_form">
                <el-form-item :label="showText.title" class="register-inpui-item" prop="name">
                    <el-input class="register-input" :placeholder="showText.title" v-model="registerData.name"></el-input>
                </el-form-item>
                <el-form-item :label="showText.phoneNum" class="register-inpui-item"  prop="username">
                    <el-input class="register-input" :placeholder="showText.phoneNum" v-model="registerData.username"></el-input>
                </el-form-item>
                <el-form-item :label="showText.passwordNum" class="register-inpui-item"  prop="password">
                    <el-input class="register-input" :placeholder="showText.passwordNum" v-model="registerData.password" show-password></el-input>
                </el-form-item >
                <el-form-item :label="showText.ConfirmPassword" class="register-inpui-item"  prop="confirmPassword">
                    <el-input class="register-input" :placeholder="showText.ConfirmPassword" v-model="registerData.confirmPassword" show-password></el-input>
                </el-form-item>
                <el-form-item class="register-btn">
                    <el-button type="primary" @click="submitForm('registerData')" class="submit-btn"> ᠳᠣᠰᠢᠶᠠᠬᠣ</el-button>
                    <el-button type="primary" @click=" goback()" class="submit-btn"> ᠪᠣᠴᠠᠬᠣ</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<style scoped>
body{
    font-family: 'MenkQaganTig';
}
.title-h2{
    font-family: 'MenkQaganTig';
    writing-mode: vertical-lr;
    font-weight: 600;
    color: #f8f8f8;
    margin-left: 0;
    height: auto;
}
.submit-btn{
    font-family: 'MenkQaganTig';
    font-weight: 600;
    color: #fffdfd;
    font-size: 20px;
}
.box {
    height: 100vh;
    background-image:url("../assets/image/login.jpg");
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.content{
    width: 600px;
    border: 1px solid #efefef60;
    display: flex;
    background: rgba(22, 22, 22, 0.6);
    border-radius: 16px;
    width: 682px;
    padding: 60px;
}
.register_form {
    display: flex;
    width: 480px;
    margin-right: 22px;
    flex-direction: column-reverse;
    transform: rotate(90deg) translate(0%, -100%);
    transform-origin: 0% 0%;
    -webkit-transform: rotate(90deg) translate(0%, -100%);
    -webkit-transform-origin: 0% 0%;
    -moz-transform: rotate(90deg) translate(0%, -100%);
    -moz-transform-origin: 0% 0%;
    -o-transform: rotate(90deg) translate(0%, -100%);
    -o-transform-origin: 0% 0%;
}
.register-input {
    margin-top: 10px;
}
.register-input ::v-deep .el-input__inner{
    height: 45px;
}
.register-inpui-item ::v-deep .el-input__inner{
    font-family: 'MenkHawangTig';
    font-size: 24px;
}

.register-inpui-item ::v-deep .el-form-item__error{
    font-family: 'MenkHawangTig';
    font-size: 24px;
    top: -38% !important;
}

.el-form-item {
    display: flex;
    flex-direction: column-reverse;
}

.register_form ::v-deep .el-form-item__label {
    font-family: 'MenkHawangTig';
    justify-content: flex-start;
    text-indent: 0.5rem;
    font-size: 22px;
    color: #f8f8f8;
}
.register-btn{
    margin-bottom: 40px;
}
</style>
<style >
.el-form-item__error {
    top: -50% !important;
    left: 8px !important;
}
</style>
<script>
import { verifyUserName, registerSubmit } from '../http/register.js'
import { router } from '../router/index.js';
import { ElMessage } from 'element-plus';
export default {
    name: 'registerView',
    data() {
        var checkUserName = (rule, value, callback) => {
            //使用正则表达式进行验证手机号码
            if (!/^1[3456789]\d{9}$/.test(value)) {
                return callback(new Error('手机号不正确！'));
            }
            verifyUserName(value).then(res => {
                if (res.code === '0') {
                    if (res.data === false) {
                        return callback(new Error('手机号已存在，请核对！'));
                    } else {
                        return callback();
                    }
                }
            });

        };
        var vConfirmPassword = (rule, value, callback) => {
            if (value !== this.registerData.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        }
        return {
            showText: {
                title: " ᠠᠯᠳᠠᠷ ",
                phoneNum: " ᠭᠠᠷ ᠣᠲᠠᠰᠦ ᠨᠣᠮᠧᠷ ",
                passwordNum: " ᠨᠢᠭᠣᠴᠠ ᠦᠭᠡ ",
                ConfirmPassword: " ᠨᠢᠭᠣᠴᠠ ᠦᠭᠡ ᠪᠠᠨ ᠯᠠᠣᠳᠠᠭᠣᠯᠠᠬᠤ "
            },
            registerData: {
                name: "",
                username: "",
                password: "",
                confirmPassword: ""
            },
            rules: {
                name: [
                    { required: true, message: ' ᠠᠯᠳᠠᠷ ᠢᠶᠠᠨ ᠣᠷᠤᠭᠤᠯᠠᠭᠠᠷᠠᠢ ', trigger: 'blur' }
                ],
                username: [
                    { required: true, message: ' ᠭᠠᠷ ᠣᠲᠠᠰᠦ ᠨᠣᠮᠧᠷ ᠢᠶᠠᠨ ᠣᠷᠤᠭᠤᠯᠠᠭᠠᠷᠠᠢ ', trigger: 'blur' },
                    { required: true, min: 11, max: 11, message: '11 ᠤᠷᠠᠨᠳᠦ ᠭᠠᠷ ᠣᠲᠠᠰᠦ ᠨᠣᠮᠧᠷ ᠢᠶᠠᠨ ᠣᠷᠤᠭᠤᠯᠠᠭᠠᠷᠠᠢ ', trigger: 'blur' },
                    { validator: checkUserName, trigger: 'blur' }
                ],
                password: [
                    { required: true, message: ' ᠨᠢᠭᠣᠴᠠ ᠦᠭᠡ ᠢᠶᠠᠨ ᠣᠷᠤᠭᠤᠯᠠᠭᠠᠷᠠᠢ ', trigger: 'blur' },
                    { required: true, min: 6, message: '6 ᠣᠷᠦᠨᠲᠦ ᠨᠢᠭᠣᠴᠠ ᠦᠭᠡ ', trigger: 'blur' }
                ],
                confirmPassword: [
                    { required: true, message: ' ᠨᠢᠭᠣᠴᠠ ᠦᠭᠡ ᠯᠠᠪᠳᠠᠭᠠᠯᠠᠭᠠᠷᠠᠢ ', trigger: 'blur' },
                    { validator: vConfirmPassword, trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log(this.registerData);
                    registerSubmit(this.registerData).then(res => {
                        if (res.code === '0') {
                            ElMessage.success("注册成功！，请使用新账号登陆系统");
                            router.push('/login')
                            return;
                        }
                        ElMessage.error(res.msg);
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        goback(){
            router.push('/login');
        }
    }
}
</script>
