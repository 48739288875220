<template>
  <el-container class="bq-container">
    <el-header class="header  hidden-xs-only">
      <el-row>
        <el-col class="header-el-col">
          <el-button type="info" class="header-btn" @click="showUnReadMessageView">
            <span class="unread" v-if="unreadCount && unreadCount !== '0'">{{ unreadCount }}</span>
            <el-icon :size="24">
              <Message />
            </el-icon>
          </el-button>
          <el-button type="primary" class="header-btn" @click="drawer = true">
            <el-icon :size="24"><Menu /></el-icon>
          </el-button>
        </el-col>
      </el-row>
      <el-drawer v-model="drawer" direction="ttb" size="20%" :with-header="false" :before-close="handleClose">
        <el-row>
          <el-col class="menu-col" :span="4">
            <!-- <el-link href="https://element.eleme.io" target="_blank">{{menu.name}}</el-link> -->
            <el-card shadow="always" @click="go('/index')" class="top-deawer">
              <i class="fa fa-television"></i>
              首页
            </el-card>
          </el-col>
          <el-col class="menu-col" :span="4" v-for="menu in menus" v-bind:key="menu">
            <el-card shadow="always" @click="go(menu.url)" class="top-deawer">
              <i :class="menu.icon"></i>
              {{ menu.name }}
            </el-card>
          </el-col>
        </el-row>
      </el-drawer>
    </el-header>
    <el-container>
      <el-aside width="276px" class="aside hidden-xs-only">
        <el-row class="user_container_top">
          <el-col :span="24" hidden-xs-only>
            <div class="user_container_top_col">
              <el-avatar :size="80" :src="circleUrl"></el-avatar>
            </div>
          </el-col>
        </el-row>
        <el-descriptions class="user_Info rotate" title="基本信息" column="1">
          <el-descriptions-item label="地址：">{{ user.address }}</el-descriptions-item>
          <el-descriptions-item label="联系电话：">{{ user.username }}</el-descriptions-item>
          <el-descriptions-item label="出生年月：">{{ user.birthday }}</el-descriptions-item>
          <el-descriptions-item label="性别：">{{ user.sex }}</el-descriptions-item>
          <el-descriptions-item label="姓名：">{{ user.name }}</el-descriptions-item>
        </el-descriptions>
        <el-divider></el-divider>
        <el-row>
          <el-col :span="24">
            <el-menu default-active="0" router="ture" class="el-menu-vertical" collapse="false">
              <el-menu-item placement="top-start" router="/index" index="/index">
                <el-icon :size="24" class="fa fa-television "></el-icon>
                <span>首页</span>
              </el-menu-item>
              <el-menu-item :router="menu.url" v-for="menu in menus" :key="menu.name" :index="menu.url"
                placement="top-start" class="menu-item">
                <el-icon :size="24"  :class="menu.icon"></el-icon>
                <span>{{ menu.name }}</span>
              </el-menu-item>
              <el-menu-item placement="top-start" router="/menkFontFamiy" index="/menkFontFamiy">
                <el-icon :size="24"><Cellphone /></el-icon>
                <span>ᠮᠦᠩᠬᠡ ᠭᠠᠯ ᠪᠢᠴᠢᠯᠬᠡ ᠲᠢᠭ </span>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
    <el-footer class=" hidden-xs-only">

    </el-footer>
  </el-container>
  <el-dialog v-model="unReadMessageDialog" width="500px" :show-close="false" :center="true">
    <div class="message-dialog-body">
      <el-form class="message-dialog-form">
        <el-card class="message-card" v-for="item in message" :key="item.value" @click="showMessageDataileView(item.id)">
          <el-row class="message-body">
            <el-col class="message-title">
              <h3 v-html="item.content"></h3>
            </el-col>
            <el-col class="message-subsidiary">
              <el-icon :size="16">
                <User />
              </el-icon>&nbsp;
              <span>{{ item.sendUserName }}</span>
              &nbsp;
              <el-icon :size="16">
                <Clock />
              </el-icon>&nbsp;
              <span>{{ item.deliveryTime }}</span>
            </el-col>
          </el-row>
        </el-card>
        <el-button class="message-back-btn" type="danger" @click="unReadMessageDialog = false"> ᠪᠣᠴᠠᠬᠣ </el-button>
      </el-form>
    </div>
  </el-dialog>
  <el-dialog v-model="messageDetaileDialog" width="800px" :show-close="false" :center="true">
    <h2> ᠱᠢᠠᠩᠴᠢᠩ</h2>
  </el-dialog>
</template>
<script>
import $http from '../http/index.js';
import { router } from '../router/index.js';
import img from '../assets/image/fase.jpg';
import { onMounted, ref } from 'vue'
const isCollapse = ref(true)
const drawer = ref(false)
export default {
  name: "IndexView",
  setup() {
    const menus = ref([]);
    const unreadCount = ref("");
    const user = ref({
      name: '',
      sex: '',
      birthday: '',
      username: '',
      address: ''
    });
    const message = ref([]);
    const circleUrl = ref();
    const unReadMessageDialog = ref(false)
    const messageDetaileDialog = ref(false)
    onMounted(async () => {
      //dom 挂载后
      getUserData();
      getMenuData();
      getUnReadMessageCount();
    })
    //获取用户信息
    const getUserData = () => {
      $http.get("/getUserByToken").then(src => {
        if (src.data) {
          src.data.sex = src.data.sex === '1' ? '男' : '女';
          src.data.birthday = src.data.birthday ? src.data.birthday.substr(0, 10) : '';
          user.value = src.data;
          circleUrl.value = src.data.photo ? src.data.photo : img
        }
      });
    };
    //获取菜单信息
    const getMenuData = () => {
      $http.get("/getMyMune").then(src => {
        menus.value = src.data[0].childs;
      })
    };
    //获取未读消息个数
    const getUnReadMessageCount = () => {
      $http.get("/message/getUnReadConst").then(res => {
        unreadCount.value = res.data;
      })
    };
    const showUnReadMessageView = () => {
      unReadMessageDialog.value = true;
      $http.get("/message/getUnReadList").then(res => {
        //res 返回集合
        message.value = res.data;
        console.log(message.value);
      });
    }
    const showMessageDataileView =(id)=>{
      console.log(id);
      $http.get("/message/getMessageDatails/"+id).then(res=>{
        console.log(res);
      })
      messageDetaileDialog.value = true;
    }
    const go = (url) => {
      drawer.value = false
      router.push(url)
    }
    return {
      circleUrl: ref(img),
      user,
      unreadCount,
      menus,
      isCollapse,
      drawer,
      unReadMessageDialog,
      messageDetaileDialog,
      message,
      go,
      showUnReadMessageView,
      showMessageDataileView
    }
  }
}
</script>
<style src="../assets/css/font-awesome.css" scoped></style>
<style >
.el-overlay .el-dialog--center {
  border-radius: 10px;
}
</style>

<style scoped>
.message-card {
  margin-right: 10px;
}

.message-dialog-form {
  display: flex;
  flex-direction: row;
}

.message-body {
  writing-mode: vertical-lr;
  display: flex;
  flex-direction: column;
}

.message-title {
  font-family: 'MenkBudunTig';
  height: 50vh;
}

.message-title h3 {
  -webkit-line-clamp: 3;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: normal;
}

.message-subsidiary {
  font-family: 'MenkQaganTig';
  font-size: 20px;
  text-align: end;
  margin: 4px;
}
.message-back-btn {
  padding: 10px;
}

.message-back-btn ::v-deep span {
  writing-mode: vertical-lr;
  font-family: 'MenkQaganTig';
  font-size: 22px;
}

.header .header-el-col {
  display: flex;
  flex-direction: row-reverse;
  justify-content: end;
}

.header .header-el-col .header-btn {
  margin-top: 10px;
  height: 40px;
  padding: 0 10px;
  width: 60px;
  margin-right: 10px;
}

header .header-el-col .header-btn .unread {
  border: 1px solid red;
  background-color: red;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 18px;
  position: absolute;
  top: 4px;
  right: 4px;
}

::v-deep .el-divider__text {
  background-color: rgba(255, 255, 255, 0);
}

::v-deep .el-descriptions :not(.is-bordered) td {
  padding-bottom: 0;
  white-space: pre;
  font-size: 14px;
  color: #d1d1d1;
}

.el-menu--collapse {
  border: 0;
  width: 100%;
  height: 200px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start
}

.menu-item div {
  padding: 0 6px;
}
.bq-container .el-main {
  margin: 20px;
  background: #fff;
  border-radius: 10px;
} 
.top-deawer {
  background: #fff;
  border-radius: 10px;
  height: 10vh;
  line-height: 10vh;
  font-size: 20px;
  margin: 20px;
}

.top-deawer ::v-deep .el-card__body {
  padding: 0;
}

.transition-box {
  margin-bottom: 10px;
  width: 200px;
  height: 100px;
  border-radius: 4px;
  background-color: #409eff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
  margin-right: 20px;
}

.user_container_top {
  height: 132px;
  background-image: url("../assets/image/aside-1.jpg");
  background-size: cover;
}

.el-container {
  background-color: #eaeef3;
}

.aside {
  /* background-image: url("../assets/image/background_aside.png"); */
  background-size: 30px;
  text-align: center;
  line-height: 200px;
  margin: 20px 0 20px 20px;
  background-color: #fff;
  border-radius: 10px;

}

.user_container_top_col {
  margin-top: 86px;
  margin-left: 20px;
  display: flex;
}

.user_Info {
  display: flex;
  flex-direction: column-reverse;
  margin: 60px 10px 0 0;
}
::v-deep .el-descriptions__title{
  line-height: 40px;
}
::v-deep .el-descriptions__cell{
  padding: 0;
}
::v-deep .el-descriptions__label{
    font-size: 14px;
    font-weight: 600;
}
.rotate {
  transform: rotate(90deg) translate(0%, -100%);
  transform-origin: 0% 0%;
  -webkit-transform: rotate(90deg) translate(0%, -100%);
  -webkit-transform-origin: 0% 0%;
  -moz-transform: rotate(90deg) translate(0%, -100%);
  -moz-transform-origin: 0% 0%;
  -o-transform: rotate(90deg) translate(0%, -100%);
  -o-transform-origin: 0% 0%;
}

.el-header,
.el-footer {
  background-color: #B3C0D1;
  /* background-image: url("../assets/image/background_header.png");-->*/
  background-size: 80px;
  color: #333;
  text-align: center;
}

.el-container.is-vertical {
  flex-direction: column;
  height: 100vh;
}

.el-aside {
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
}

.el-menu-vertical {
  padding: 0px 10px;
}

.el-menu-vertical:not(.el-menu--collapse) {
  width: 400px;
  min-height: 400px;
}
</style>
