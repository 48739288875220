import { createRouter, createWebHistory } from "vue-router";
import register from '../views/Register.vue'
import login from '../views/Login.vue'
import home from '../views/Home.vue'
import index from '../views/Index.vue'
const routes = [
  {
    path: "/index",
    name: 'IndexView',
    component: index,
    children: [
      {
        path: '',
        name: 'menuVue',
        component: () => import('../views/menu.vue'),
        props: true,
        meta: { title: "菜单" }
      },
      {
        name: 'addArticle',
        path: '/addArticle',
        component: () => import('../views/article/article_add.vue'),
      },
      {
        path: "/articleList",
        component: () => import('../views/article/article_list.vue')
      },
      {
        name: 'articleDetails',
        path: "/articleDetails",
        component: () => import('../views/article/article.vue')
      }, {
        path: "/collect",
        component: () => import('../views/collect/collect.vue')
      },
      {
        path: "/settings",
        component: () => import('../views/settings.vue')
      },
      {
        path: "/collectArticleList",
        name: "collectArticleList",
        component: () => import("../views/collect/collect_article_list")
      },
      {
        path: "/feedback",
        component: () => import('../views/feedback/feedback.vue')
      },
      {
        path: "/menkFontFamiy",
        name: "menkFontFamiyVue",
        component: ()=>import('@/views/MenkFontFamily.vue'),
    
      }

    ]
  },
  {
    path: "/login",
    name: "LoginView",
    component: login,

  },
  {
    path: "/",
    name: "homeView",
    component: home,
  },
  {
    path: "/register",
    name: "registerView",
    component: register
  }
]
export const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem("token");
  const outerPaths = ['/', '/api', '/login','/img' ,'/register', '/account/forget', '/auth', '/home/system/wph_set', '/home/system/pdd_set'];
  if (!token && !outerPaths.includes(to.path)) {
    next('/login');
  } else {
    document.title = 'biqigesu'
    next();
  }
})
export default router;