import axios from 'axios'
import {router} from '../router/index.js';
const $http = axios.create({
    baseURL:'/api',
    timeout:5000,
    headers:{
       
    }
});
/* const $weixin = axios.create({
    baseURL:'/weixin',
    timeout:5000,
    headers:{

    }
}) */

//添加请求拦截器
$http.interceptors.request.use(function(config){
    //发送之前做点什么
    let token = sessionStorage.getItem("token");
    if(token){
        config.headers.token = token;
    }
    return config;
},function(error){
    //响应错误做点什么
    return Promise.reject(error);
});

//添加响应连接器
$http.interceptors.response.use(function(response){
    //对响应数据做点什么
    let data = response.data;
    if(data.code===44001){
        router.push("/login");
    }   
    return data;
},function(error){
    console.log("报错了");
    console.log(error);
    //响应错误做点什么
    return Promise.reject(error);
});
export default $http