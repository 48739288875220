import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import axios from 'axios'
import 'jquery'

import './assets/css/global.css'
// 引入字体
import './assets/fonts/MenksoftOpenType1.02/menksoftTig.css'
import './assets/fonts/OyunTig.css'
// 引入summernote

import 'summernote'
import 'summernote/dist/summernote.css'
import './assets/js/summernote-zh-MN.js'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'popper.js'
//import 'summernote/dist/summernote/s'


axios.defaults.baseURL ='/api'
const app = createApp(App)
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
app.use(router).use(ElementPlus)
app.mount('#app')
